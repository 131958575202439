<script>
import FileUploader from "@/components/form-components/FileUploader";
export default {
  name: "PaymentSuccess",

  components: {
    FileUploader,
    personal: () =>
      import(/* webpackChunkName: "Dashboard" */ "../components/Personal.vue"),
    security: () =>
      import(/* webpackChunkName: "Dashboard" */ "../components/Security.vue")
  },

  data: () => ({
    verificationStep: 2,
    dialog: false,
    scrolled: false,
    notScrolled: true,
    avatar: null,
    showMenu: false,
    x: 0,
    y: 0,
    loading: false,
    model: {}
  }),

  computed: {
    currentComponent() {
      return this.$options.components?.[this.$route.params.action];
    },
    user() {
      return this.$store.getters["user/info"];
    }
  },

  async beforeMount() {
    this.scroll();
    // await this.getData();
  },

  methods: {
    scroll() {
      window.addEventListener("scroll", () => {
        const top = window.pageYOffset;

        this.notScrolled = top === 0;
        this.scrolled = top > 250;
      });
    },
    async changeAvatar() {
      try {
        const user = await this.$API
          .profile()
          .changeAvatar(Number(this.user.id), { avatar: this.avatar });
        const credentials = this.$store.getters["authentication/credentials"];
        credentials.user.avatar = user.avatar;

        await this.$store.dispatch("authentication/login", credentials);
        await this.$store.dispatch("user/set", credentials.user);
        this.dialog = false;

        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_edit")
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async updateProfile() {
      try {
        const user = await this.$API
          .profile()
          .updateProfile(Number(this.user?.id), { avatar: this.avatar });
        const credentials = this.$store.getters["authentication/credentials"];
        credentials.user.avatar = user.avatar;

        await this.$store.dispatch("authentication/login", credentials);
        await this.$store.dispatch("user/set", credentials.user);
        this.dialog = false;

        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_edit")
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async getData() {
      try {
        this.model = await this.$API.users().profile(this.user?.id);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async logout() {
      try {
        await this.$store.dispatch("authentication/logout");
        document.location.href = origin;
      } catch (e) {
        await this.$store.dispatch("alerts/showError", e.message);
      }
    }
  }
};
</script>

<template>
  <div>
    <v-container
      class="fill-height px-6 mt-2 mx-auto"
      style="max-width: 1200px"
    >
      <div
        class="grid-container"
        :class="{ 'd-block': $vuetify.breakpoint.mdAndDown }"
      >
        <div>
          <v-card elevation="2" class="fixed cart-container">
            <v-card-text>
              <div class="text-center position-relative avatar">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-avatar
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      size="148"
                      style="border: 4px solid"
                    >
                      <v-img
                        v-if="user && user.avatar"
                        :src="user.avatar.url"
                        class="avatar"
                      ></v-img>
                      <span v-else class="white--text text-h4 font-weight-bold">
                        {{
                          user &&
                            user.name
                              .split(" ")
                              .map(([item]) => item)
                              .slice(0, 2)
                              .join("")
                              .toUpperCase()
                        }}
                      </span>
                      <span
                        class="
                          display-icon
                          new-avatar
                          full-width
                          d-flex
                          justify-center
                          align-center
                        "
                        @click="dialog = true"
                      >
                        <v-icon>mdi-camera</v-icon>
                      </span>
                    </v-avatar>
                  </template>
                  <div>{{ $t("profile.form.fields.change_avatar") }}</div>
                </v-tooltip>
                <v-menu
                  v-model="showMenu"
                  :position-x="x"
                  :position-y="y"
                  absolute
                  offset-y
                  class="pa-0 ma-0"
                >
                  <v-list dense class="py-0">
                    <v-list-item link @click="dialog = true">
                      <v-list-item-title>
                        <v-icon small class="mr-2">mdi-pencil</v-icon>
                        <span>
                          {{ $t("profile.form.fields.change_avatar") }}
                        </span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-icon small class="mr-2">mdi-delete</v-icon>
                        <span>
                          {{ $t("profile.form.fields.delete_avatar") }}
                        </span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div class="font-weight-bold body-1 text-center mt-8">
                {{ user && user.name }}
              </div>
              <div class="body-2 text-center mb-2">
                {{ user && user.email }}
              </div>
            </v-card-text>
            <v-list class="pr-3" shaped>
              <v-list-item :to="`/profile/tab/personal`" color="primary">
                <v-list-item-icon>
                  <v-icon>mdi-card-account-details-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <span v-on="on" v-bind="attrs">
                          {{ $t("profile.headers.personal-title") }}
                        </span>
                      </template>
                      <span> {{ $t("profile.headers.personal-title") }} </span>
                    </v-tooltip>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="`/profile/tab/security`" color="primary">
                <v-list-item-icon>
                  <v-icon>mdi-shield-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("profile.headers.security-title") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <template>
                <v-divider class="my-5 mr-2"></v-divider>
                <v-list-item @click="logout" color="primary">
                  <v-list-item-icon>
                    <v-icon>mdi-exit-to-app</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("menu.exit") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </div>
        <div>
          <v-fade-transition leave-absolute hide-on-leave>
            <component
              :key="1"
              :is="currentComponent"
              :user="user"
              :profile="model"
            />
          </v-fade-transition>
        </div>
      </div>
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card v-if="dialog">
          <v-card-title>
            <span class="headline">
              {{ $t("profile.form.fields.change_avatar") }}
            </span>
          </v-card-title>
          <v-card-text class="py-0">
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <file-uploader v-model="avatar" label="Прикрепить файл" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined text @click="dialog = false">
              {{ $t("global_buttons.close") }}
            </v-btn>
            <v-btn
              :disabled="!avatar || loading"
              color="primary"
              depressed
              @click="updateProfile"
            >
              {{ $t("global_buttons.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
.grid-container {
  display: grid;
  height: 100%;
  grid-column-gap: 20px;
  grid-template-columns: 300px 1fr;
}
.fixed {
  position: sticky;
  top: 20px;
  transition: 0.3s;
}
.user-avatar {
  cursor: pointer;
  .user-camera {
    display: none;
  }

  &:hover {
    span:first-child {
      display: none;
    }
    .user-camera {
      display: block;
    }
  }
}

.delete-icon {
  position: absolute;
  visibility: hidden;
  bottom: 0;
  right: 0;
  z-index: 1111111 !important;
}

.new-avatar {
  position: absolute;
  visibility: hidden;
  bottom: 0;
  z-index: 2111111111;
  height: 100%;
  left: 0;
  background-color: var(--v-primary-base);
  color: #fff;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.avatar:hover {
  .display-icon {
    visibility: visible !important;
  }
}

@media (max-width: 1264px) {
  .container.fill-height {
    justify-content: center !important;
  }
  .cart-container {
    margin-bottom: 10px;
  }
}
</style>
