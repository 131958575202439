var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"fill-height px-6 mt-2 mx-auto",staticStyle:{"max-width":"1200px"}},[_c('div',{staticClass:"grid-container",class:{ 'd-block': _vm.$vuetify.breakpoint.mdAndDown }},[_c('div',[_c('v-card',{staticClass:"fixed cart-container",attrs:{"elevation":"2"}},[_c('v-card-text',[_c('div',{staticClass:"text-center position-relative avatar"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticStyle:{"border":"4px solid"},attrs:{"color":"primary","size":"148"}},'v-avatar',attrs,false),on),[(_vm.user && _vm.user.avatar)?_c('v-img',{staticClass:"avatar",attrs:{"src":_vm.user.avatar.url}}):_c('span',{staticClass:"white--text text-h4 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.user && _vm.user.name .split(" ") .map(function (ref) {
	var item = ref[0];

	return item;
}) .slice(0, 2) .join("") .toUpperCase())+" ")]),_c('span',{staticClass:"\n                        display-icon\n                        new-avatar\n                        full-width\n                        d-flex\n                        justify-center\n                        align-center\n                      ",on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',[_vm._v("mdi-camera")])],1)],1)]}}])},[_c('div',[_vm._v(_vm._s(_vm.$t("profile.form.fields.change_avatar")))])]),_c('v-menu',{staticClass:"pa-0 ma-0",attrs:{"position-x":_vm.x,"position-y":_vm.y,"absolute":"","offset-y":""},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-pencil")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("profile.form.fields.change_avatar"))+" ")])],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-delete")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("profile.form.fields.delete_avatar"))+" ")])],1)],1)],1)],1)],1),_c('div',{staticClass:"font-weight-bold body-1 text-center mt-8"},[_vm._v(" "+_vm._s(_vm.user && _vm.user.name)+" ")]),_c('div',{staticClass:"body-2 text-center mb-2"},[_vm._v(" "+_vm._s(_vm.user && _vm.user.email)+" ")])]),_c('v-list',{staticClass:"pr-3",attrs:{"shaped":""}},[_c('v-list-item',{attrs:{"to":"/profile/tab/personal","color":"primary"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-card-account-details-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("profile.headers.personal-title"))+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("profile.headers.personal-title"))+" ")])])],1)],1)],1),_c('v-list-item',{attrs:{"to":"/profile/tab/security","color":"primary"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-shield-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("profile.headers.security-title"))+" ")])],1)],1),[_c('v-divider',{staticClass:"my-5 mr-2"}),_c('v-list-item',{attrs:{"color":"primary"},on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-exit-to-app")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("menu.exit"))+" ")])],1)],1)]],2)],1)],1),_c('div',[_c('v-fade-transition',{attrs:{"leave-absolute":"","hide-on-leave":""}},[_c(_vm.currentComponent,{key:1,tag:"component",attrs:{"user":_vm.user,"profile":_vm.model}})],1)],1)]),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("profile.form.fields.change_avatar"))+" ")])]),_c('v-card-text',{staticClass:"py-0"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('file-uploader',{attrs:{"label":"Прикрепить файл"},model:{value:(_vm.avatar),callback:function ($$v) {_vm.avatar=$$v},expression:"avatar"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("global_buttons.close"))+" ")]),_c('v-btn',{attrs:{"disabled":!_vm.avatar || _vm.loading,"color":"primary","depressed":""},on:{"click":_vm.updateProfile}},[_vm._v(" "+_vm._s(_vm.$t("global_buttons.save"))+" ")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }